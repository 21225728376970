
































import Component from 'vue-class-component';
import SettingsBoxItem from '@/views/settings/SettingsBoxItem.vue';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { Getter, namespace } from 'vuex-class';
import CommunityUser from '@/models/graphql/CommunityUser';
import PreferencesType from '@/utils/enums/PreferencesType';
import CommunityUserPreferences from '@/models/graphql/CommunityUserPreferences';
import ToastActionParams from '@/utils/types/ToastActionParams';
import ToastActionType from '@/utils/enums/ToastActionType';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import CommunityFeature from '@/models/graphql/CommunityFeature';

const communityUserPreferencesStore = namespace('CommunityUserPreferencesStore');
const toastStore = namespace('ToastStore');

@Component({
  components: {
    SettingsBoxItem,
  },
  beforeRouteEnter(from, to, next) {
    next((vm) => {
      if (!((vm as NotificationsSection).hasCompanyInvitationFeature
        || (vm as NotificationsSection).hasCompanyMeetingFeature
        || (vm as NotificationsSection).hasConnectionFeature)) {
        vm.$router.replace({ name: 'not-found' });
      }
    });
  },
})
export default class NotificationsSection extends BreakpointWrapper {
  @Getter
  protected readonly authUser!: CommunityUser;

  @Getter
  private featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @communityUserPreferencesStore.Action
  private changePreferences!: (preferences: object) => Promise<CommunityUserPreferences | undefined>;

  @communityUserPreferencesStore.Action
  private loadMyPreferences!: () => Promise<CommunityUserPreferences | undefined>;

  @toastStore.Action
  private addNewAction!: (payload: ToastActionParams) => void;

  private PreferencesType = PreferencesType;

  private FeatureKeys = FeatureKeys;

  private isLoading = true;

  private get hasCompanyInvitationFeature(): boolean {
    return this.isFeatureActivated(FeatureKeys.COMMUNITY_ENABLE_COMPANY_INVITATION);
  }

  private get hasConnectionFeature(): boolean {
    return this.isFeatureActivated(FeatureKeys.COMMUNITY_CONNECTIONS_FEATURE);
  }

  private get hasCompanyMeetingFeature(): boolean {
    return this.isFeatureActivated(FeatureKeys.COMMUNITY_ENABLE_COMPANY_MEETING);
  }

  created(): void {
    this.loadMyPreferences()
      .then((preferences) => {
        this.authUser.preferences = preferences;
        this.isLoading = false;
      });
  }

  private isFeatureActivated(code: FeatureKeys): boolean {
    return this.featureByKey(code) && this.featureByKey(code).enabled;
  }

  private togglePreferences(type: PreferencesType): void {
    const preferences = {
      emailConnectionRequest: !!this.authUser.preferences?.emailConnectionRequest,
      emailNewMessage: !!this.authUser.preferences?.emailNewMessage,
      emailMeetingInvitation: !!this.authUser.preferences?.emailMeetingInvitation,
      emailCompanyInvitation: !!this.authUser.preferences?.emailCompanyInvitation,
    };
    switch (type) {
      case PreferencesType.CONNECTION:
        preferences.emailConnectionRequest = !preferences.emailConnectionRequest;
        break;
      case PreferencesType.MESSAGE:
        preferences.emailNewMessage = !preferences.emailNewMessage;
        break;
      case PreferencesType.MEETING:
        preferences.emailMeetingInvitation = !preferences.emailMeetingInvitation;
        break;
      case PreferencesType.COMPANY:
        preferences.emailCompanyInvitation = !preferences.emailCompanyInvitation;
        break;
      default:
        break;
    }
    this.changePreferences(preferences).then(() => {
      this.addNewAction(
        { type: ToastActionType.SAVE_CHANGES },
      );
    });
  }
}
